.nav-bar {
  padding: 1em 1em;
  /* background-color: rgb(8, 90, 152); */
  background-color: rgb(28,112,116);
  display: flex;
  justify-content: space-between;
}

.nav-link {
  display: inline-block;
  padding: 0.5em 1em;
  color: rgb(255, 255, 255);
  font-weight: bold;
  text-decoration: none;
}

.nav-link-active {
  color: rgb(255, 255, 255);
  border-bottom: 2px solid;
}

.nav-bar-button {
  color: rgb(255, 255, 255);
}

.nav-bar-title {
  display: inline-block;
  padding: 0.5em 1em;
  font-weight: bold;
  font-size: 40;
  color: rgb(255, 255, 255);
}